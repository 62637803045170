import { useEffect, useRef, useState } from 'react';
import {
    useProjectFiltersQuery,
    useProjectQuery,
    useProjectsQuery,
} from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import {
    getCurrentProjectSelectedDateRange,
    getCurrentProjectId,
    getCurrentProjectSelectedHabitatAssayType,
} from 'src/app/projects/views/project-list/state/projectListSelector';
import {
    setCurrentProjectId as saveCurrentProjectId,
    setHabitatAssayType,
} from 'src/app/projects/views/project-list/state/projectListSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentCustomerDetails } from 'src/app/customers/state/customersSelector';
import { HabitatAssayLozengeOption } from '../components/lozenges/HabitatAssayLozenges';
import { Project, HabitatAssay, ProgressStatus, SubscriptionType } from '../types/graph/project';
import { SelectOption } from '../components/select/Select';
import {
    availableAreaColors,
    habitatInsightsLandTypeColors,
    habitatInsightsPrimaryProductivityTypeColors,
    habitatInsightsConnectivityTypeColors,
} from '../constants/colors';

export type CurrentProjectFilters = {
    times: {
        firstSampleDate: string;
        lastSampleDate: string;
    };
    habitatAssays: HabitatAssay[];
    habitatAssayTypeOptions: HabitatAssayLozengeOption[];
    areaOptions: SelectOption[];
    habitatInsightsChartTypes: string[];
    habitatInsightsSites: string[];
    habitatInsightsMetricOptions: SelectOption[];
    habitatInsightsSiteOptions: SelectOption[];
};

export type SamplingEventFilterData = {
    name: string;
    fromDate: string;
    toDate: string;
    year: number;
    event: number;
};

const useProject = () => {
    const dispatch = useAppDispatch();
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails);
    const areaOptionsRef = useRef<SelectOption[]>([]);
    const [habitatAssayTypeOptions, setHabitatAssayTypeOptions] = useState<HabitatAssayLozengeOption[]>([]);

    const currentProjectId = useAppSelector(getCurrentProjectId);

    const currentProjectSelectedDateRange = useAppSelector(getCurrentProjectSelectedDateRange);
    const currentProjectSelectedHabitatAssayType = useAppSelector(getCurrentProjectSelectedHabitatAssayType);
    const habitatInsightsMetricOptionsRef = useRef<SelectOption[]>([]);
    const habitatInsightsSiteOptionsRef = useRef<SelectOption[]>([]);
    const samplingEventFilterDataRef = useRef<SamplingEventFilterData[]>([]);

    const {
        isFetching: isFetchingCurrentCustomerProjects,
        currentData: currentCustomerProjects,
        error,
    } = useProjectsQuery(
        {
            customerId: currentCustomerDetails?.customerId || '',
        },
        {
            skip: !currentCustomerDetails?.customerId,
        }
    );

    const setCurrentProjectId = (projectId: string | null) => {
        dispatch(saveCurrentProjectId(projectId));
    };

    if (error) {
        console.error(error);
        throw {
            error: true,
            message: 'Unable to fetch projects list.',
        };
    }

    const { currentData, isFetching } = useProjectQuery(
        {
            projectId: currentProjectId || '',
        },
        {
            skip: !currentProjectId,
        }
    );

    // Get project filters data
    const { currentData: currentFilters, isFetching: isFetchingFilters } = useProjectFiltersQuery(
        {
            projectId: currentProjectId || '',
        },
        {
            skip: !currentProjectId,
        }
    );

    // Set Sampling event options
    useEffect(() => {
        const currentProjectDetails = currentCustomerProjects?.customer.projects.find(project => project.projectId === currentProjectId);
        if (!currentProjectDetails) {
            return;
        }

        const { samplingCadence } = currentProjectDetails;
        if (samplingCadence?.events && samplingCadence?.events.length > 0) {
            const yearEventsMapping: { [key: number]: number } = {};
            const newSamplingEvents2: SamplingEventFilterData[] = [];

            samplingCadence?.events.forEach(samplingEvent => {
                if (!yearEventsMapping[samplingEvent.year]) {
                    yearEventsMapping[samplingEvent.year] = 1;
                }
                if (samplingEvent.processed && samplingEvent.processedDate) {
                    newSamplingEvents2.push({
                        name: `${samplingEvent.name}`,
                        fromDate: samplingEvent.fromDate,
                        toDate: samplingEvent.toDate,
                        year: samplingEvent.year,
                        event: yearEventsMapping[samplingEvent.year],
                    });
                }
                yearEventsMapping[samplingEvent.year]++;
            });

            samplingEventFilterDataRef.current = newSamplingEvents2;
        }
    }, [currentCustomerProjects]);

    useEffect(() => {
        if (!currentFilters?.filters || !currentFilters?.filters.map) {
            return;
        }
        const habitatAssays = currentFilters.filters.map.habitatAssays || [];

        if (!currentProjectSelectedHabitatAssayType) {
            setCurrentProjectHabitatAssayType(habitatAssays?.[0]?.key);
        }

        const areas = currentFilters.filters.map.areas || [];

        areaOptionsRef.current = [...areas].sort().map(site => ({
            value: site,
            label: site,
        }));

        const habitatInsightsChartTypes = getCurrentProjectFilters()?.habitatInsightsChartTypes || [];

        habitatInsightsMetricOptionsRef.current = [...habitatInsightsChartTypes].map(metricValue => ({
            label: metricValue.split('_').join(' '),
            value: metricValue,
        }));

        const habitatInsightsSites = getCurrentProjectFilters()?.habitatInsightsSites || [];

        habitatInsightsSiteOptionsRef.current = [...habitatInsightsSites].map(siteValue => ({
            label: siteValue.split('_').join(' '),
            value: siteValue,
        }));

        const habitatAssayTypeOptions = habitatAssays.map(entry => ({
            habitatName: entry.habitat.habitatName,
            assayName: entry.assay.assayName,
            value: entry.key,
        }));

        setHabitatAssayTypeOptions(habitatAssayTypeOptions);
    }, [currentFilters]);

    const setCurrentProjectHabitatAssayType = (habitatAssayType: string) => {
        if (!currentFilters?.filters || !currentFilters.filters.map.habitatAssays) {
            return;
        }
        const habitatAssays = currentFilters.filters.map.habitatAssays || [];
        dispatch(setHabitatAssayType(habitatAssays.find(entry => entry.key === habitatAssayType)));
    };

    const getCurrentProjectFilters = (): CurrentProjectFilters | null => {
        if (!currentFilters?.filters?.map) {
            return null;
        }

        const times = currentFilters?.filters?.map.times || {
            firstSampleDate: '',
            lastSampleDate: '',
        };
        const habitatAssays = currentFilters.filters.map.habitatAssays || [];

        return {
            times,
            habitatAssayTypeOptions,
            areaOptions: areaOptionsRef.current,
            habitatInsightsMetricOptions: habitatInsightsMetricOptionsRef.current,
            habitatInsightsSiteOptions: habitatInsightsSiteOptionsRef.current,
            habitatInsightsChartTypes: currentFilters?.filters?.map.hlcaChartTypes,
            habitatInsightsSites: currentFilters?.filters?.map.hlcaSites,
            habitatAssays,
        };
    };

    const getCurrentCustomerProjects = () => {
        return currentCustomerProjects?.customer.projects;
    };
    const getCurrentProject = (): Project | null => {
        return currentData?.project || null;
    };

    const getCurrentProjectSummary = () => {
        return getCurrentCustomerProjects()?.find(project => project.projectId === currentProjectId);
    };

    const getCurrentProjectDataAvailability = (): boolean => {
        const currentProjectSummary = getCurrentProjectSummary();

        if (!currentProjectSummary) {
            return false;
        }

        const { samplesCount } = currentProjectSummary;

        return samplesCount > 0;
    };

    const getColourForArea = (area: string) => {
        const areaIndex = areaOptionsRef.current.findIndex(entry => entry.value === area);

        if (areaIndex === -1) {
            return '#000';
        }
        return availableAreaColors[areaIndex || 0];
    };

    const getColourForHabitatInsights = (insightType: string) => {
        const insightTypeKey = insightType.split(' ').join('').toLowerCase();
        const allInsightsColors = {
            ...habitatInsightsLandTypeColors,
            ...habitatInsightsPrimaryProductivityTypeColors,
            ...habitatInsightsConnectivityTypeColors,
        };

        return allInsightsColors[insightTypeKey]?.color || '#000';
    };

    const isHabitatInsightsProject =
        getCurrentProjectSummary()?.survey?.subscription?.name === SubscriptionType.SURVEY_WITH_HABITAT_INSIGHTS;

    return {
        currentProjectId,
        currentCustomerProjects: getCurrentCustomerProjects(),
        currentProjectDetails: getCurrentProject(),
        currentProjectSummary: getCurrentProjectSummary(),
        currentProjectFilters: getCurrentProjectFilters(),
        currentProjectSelectedDateRange,
        currentProjectSelectedHabitatAssayType,
        isProjectAvailable: !!currentProjectId,
        isSurveyDesignAvailable: getCurrentProjectSummary()?.survey?.status === ProgressStatus.COMPLETED ? true : false,
        isCurrentProjectEmpty: !getCurrentProjectDataAvailability(),
        isHabitatInsightsProject,
        setCurrentProjectId,
        setCurrentProjectHabitatAssayType,
        isFetching,
        isFetchingFilters,
        isFetchingCurrentCustomerProjects,
        getColourForArea,
        getColourForHabitatInsights,
        isGRIISProject: Boolean(getCurrentProjectSummary()?.griisLookupCountries?.length),
        habitatAssayTypeOptions,
        isSingleTimepoint: currentProjectSelectedHabitatAssayType?.singleTimepoint || false,
        samplingEventFilterData: samplingEventFilterDataRef.current,
    };
};

export default useProject;
