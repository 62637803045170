import React, { useEffect } from 'react';
import useProject, { SamplingEventFilterData } from 'src/shared/hooks/useProject';
import { useIucnMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import { useAppDispatch, useAppSelector } from 'src/store';
import IucnTable from './component/IucnTable';
import { filterByRedListStatus, filterByGRIISList } from './IucnMetrics.utils';
import { updateIucnMetricUiState } from 'src/app/dashboard/state/dashboardGeneralSlice';
import styles from './IucnMetrics.module.scss';
import useDashboardAnalytics from 'src/app/dashboard/hooks/useDashboardAnalytics';
import useIsViewed from 'src/shared/hooks/useIsViewed';
import useRefWithRerender from 'src/shared/hooks/useRefWithRerender';
import useFeatures from 'src/shared/hooks/useFeatures';

import MetricCard from 'src/shared/components/metric-card/MetricCard';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import IUCNLogo from 'src/assets/images/dashboard/iucn.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputLabel from '@mui/material/InputLabel';
import SamplingEventFilter from 'src/shared/components/filters/sampling-event-filter/SamplingEventFilter';

type IucnMetricsProps = {
    isSingleTimepoint?: boolean;
    hideDateFilter?: boolean;
};
const IucnMetrics = (props: IucnMetricsProps) => {
    const { isSingleTimepoint, hideDateFilter } = props;
    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType, samplingEventFilterData } = useProject();
    const uiState = useAppSelector(getCurrentDashboardUIState);
    const dispatch = useAppDispatch();
    const [ref, callbackRef] = useRefWithRerender<HTMLDivElement>(null);
    const isViewed = useIsViewed(ref);
    const { trackIUCNTableViewed } = useDashboardAnalytics();
    const { hasIucnRedListFeature, hasInvasivesFeature } = useFeatures();

    const {
        iucnMetric: { samplingEvent },
    } = uiState;

    const { currentData, isFetching } = useIucnMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: samplingEvent?.fromDate,
                toDate: samplingEvent?.toDate,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType || !samplingEvent,
        }
    );

    useEffect(() => {
        if (isViewed) {
            trackIUCNTableViewed();
        }
    }, [isViewed]);

    useEffect(() => {
        if (!samplingEventFilterData || samplingEventFilterData.length === 0) {
            return;
        }

        if (!samplingEvent) {
            onTimePointChange(samplingEventFilterData[0]);
        }
    }, [samplingEventFilterData]);

    const onTimePointChange = (samplingEventValue: SamplingEventFilterData) => {
        dispatch(updateIucnMetricUiState({ samplingEvent: samplingEventValue }));
    };

    if (!currentData || isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const { iucnMetrics: iucnMetricsData } = currentData;
    const { taxonomySummary } = iucnMetricsData;

    const iucnRedLists = filterByRedListStatus(taxonomySummary);
    const invasiveLists = filterByGRIISList(taxonomySummary);

    const header = (
        <div>
            <div className={styles.filter} ref={callbackRef}>
                <div className={styles.title}>Results in this table are only displayed for one sampling event</div>
                <div>|</div>
                <div className={styles.filter}>
                    <InputLabel className={styles.filterLabel}>Display detections in</InputLabel>
                    <div className={styles.filterSelect}>
                        <SamplingEventFilter
                            selectedSamplingEvent={samplingEvent}
                            samplingEventFilterData={samplingEventFilterData}
                            onChange={value => onTimePointChange(value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const tables = (
        <div>
            {hasIucnRedListFeature && (
                <IucnTable data={[...iucnRedLists]} title='IUCN Red List species' type='redLists' helpArticleSlug='iucn-status' />
            )}
            {hasInvasivesFeature && (
                <IucnTable data={[...invasiveLists]} title='Invasive species' type='invasiveLists' helpArticleSlug='invasive-species' />
            )}
        </div>
    );

    if (isSingleTimepoint) {
        return (
            <>
                {hasIucnRedListFeature && (
                    <MetricCard
                        header={'IUCN Red List'}
                        content='Red List species detected'
                        helpText={<QuestionButtonHelp type='api' placement='right' slug='iucn-status' />}
                        total={iucnRedLists.length}
                        logo={<img src={IUCNLogo} alt='Logo' style={{ marginRight: '5px' }} />}
                    />
                )}
                {hasInvasivesFeature && (
                    <MetricCard
                        header={'Invasive Species'}
                        content='invasive species detected'
                        helpText={<QuestionButtonHelp type='api' placement='right' slug='invasive-species' />}
                        total={invasiveLists.length}
                        logo={<InfoOutlinedIcon style={{ color: '#E42322', marginRight: '5px', rotate: '180deg' }} />}
                    />
                )}
            </>
        );
    }

    return (
        <div className={styles.iucnMetrics}>
            {!hideDateFilter && header}
            {tables}
        </div>
    );
};

export default IucnMetrics;
